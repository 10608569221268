import React from "react";
import { RichText } from "prismic-reactjs";
import moment from "moment";
import get from "lodash/get";
import { graphql, Link } from "gatsby";
import Layout from "@components/layout";
//import { withPreview } from "gatsby-source-prismic-graphql";

import "@scss/page.scss";
import "@scss/mix-la.scss";

export const mixLaQuery = graphql`
  query interviewListQuery($uid: String) {
    allPrismicInterview(sort: { fields: data___date, order: DESC }) {
      edges {
        node {
          data {
            title {
              text
              html
              raw
            }
            date
            excerpt {
              text
              html
              raw
            }
            thumbnail {
              url
            }
          }
          uid
        }
      }
    }
    allPrismicMixLa(filter: { uid: { eq: $uid } }) {
      edges {
        node {
          data {
            title {
              text
            }
          }
          uid
        }
      }
    }
  }
`;

const MixLaList = (props) => {
  const data = props.data;

  const currentPage = get(data, "allPrismicMixLa.edges[0].node");
  const allInterviews = get(data, "allPrismicInterview.edges");
  return (
    <Layout>
      <div className="general-page mix-la" id={currentPage.uid}>
        <section>
          {currentPage.title && (
            <h2>{RichText.asText(currentPage.data.title.raw)}</h2>
          )}
          {currentPage.header_image && (
            <img src={currentPage.data.header_image.url} />
          )}
          {currentPage.data.content && (
            <div className="general-page__content">
              {RichText.render(currentPage.data.content.raw)}
            </div>
          )}
          <ul>
            {allInterviews &&
              allInterviews.map((interview, index) => {
                var date = moment(interview.node.data.date);
                return (
                  <article className="mix-la__interview clearfix">
                    {interview.node.data.thumbnail && (
                      <div className="mix-la__interview--left">
                        <img
                          className="mix-la__interview--thumbnail"
                          src={interview.node.data.thumbnail.url}
                        />
                      </div>
                    )}
                    <div className="mix-la__interview--right">
                      <h3>
                        <a href={`/mix-la/${interview.node.uid}/`}>
                          {RichText.asText(interview.node.data.title.raw)}
                        </a>
                      </h3>
                      <time>{date.format("dddd, MMMM Do YYYY")}</time>
                      {RichText.render(interview.node.data.excerpt.raw)}
                      <div className="mix-la__interview--cta">
                        <Link to={`/mix-la/${interview.node.uid}/`}>
                          Read Article
                        </Link>
                      </div>
                    </div>
                  </article>
                );
              })}
          </ul>
        </section>
      </div>
    </Layout>
  );
};

export default MixLaList;
